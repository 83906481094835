const colors = require('tailwindcss/colors')
  
module.exports = {
  purge: [
    './src/**/*.html',
    './src/**/*.jsx',
    './src/**/*.tsx',
  ],
    theme: {
    extend: {
      backgroundImage: {
        'bv-background': "url('/img/bv-background-new2.png')",
       },
      colors: {
        teal: colors.teal,
        cyan: colors.cyan,
      },
    },
  },
  plugins: [
    // ...
    require('@tailwindcss/forms'),
    require('@tailwindcss/aspect-ratio'),
  ],
}
