import React, { SVGProps } from 'react';

const StyledComponentIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" viewBox="0 0 64 64" {...props}>
    <g clipPath="url(#prefix__clip0)">
      <path
        d="M7.135 56.265l-.34-.44c-.23-.28-.565-.695-.99-1.22l-1.54-1.775a79.758 79.758 0 00-2.1-2.25 154.93 154.93 0 00-2.58-2.595c-.925-.915-1.91-1.87-2.945-2.85-2.06-1.96-4.3-4.025-6.57-6.07-2.27-2.045-4.59-4.055-6.77-5.95-1.095-.945-2.15-1.86-3.155-2.73-.5-.425-.98-.84-1.45-1.245-.445-.375-.84-.695-1.19-.955-.71-.53-1.205-.81-1.625-.99a5.789 5.789 0 00-1.145-.36 4.965 4.965 0 00-.755-.105c-.175-.015-.265-.015-.265-.015a6.27 6.27 0 01-6.25-6.285 6.27 6.27 0 016.93-6.215l.585.065s.295.03.84.125c.545.095 1.335.255 2.31.56.98.305 2.13.75 3.385 1.445 1.255.685 2.52 1.62 3.6 2.57.54.475 1.055.96 1.53 1.425.455.445.92.905 1.405 1.375.955.935 1.96 1.915 3 2.935 1.04 1.025 2.115 2.085 3.21 3.16 1.09 1.085 2.2 2.19 3.305 3.295a355.606 355.606 0 016.45 6.61c1.02 1.07 1.995 2.12 2.91 3.125C7.84 41.915 8.7 42.87 9.5 43.75c1.605 1.775 2.945 3.255 3.88 4.295.46.495.82.885 1.07 1.155l.385.4a5.101 5.101 0 01-.14 7.21 5.097 5.097 0 01-7.56-.545zM33.185 33.635s-2.415-1.17-6.045-2.92c-.905-.44-1.89-.91-2.925-1.415-.26-.125-.525-.255-.79-.38-.25-.115-.5-.235-.755-.355l-1.685-.795C18.71 26.7 16.34 25.59 14 24.605c-2.34-.985-4.65-1.84-6.805-2.465a44.605 44.605 0 00-3.09-.785 28.975 28.975 0 00-2.65-.43c-1.62-.18-2.905-.17-3.77-.1-.435.03-.77.07-.995.1l-.345.045-.085.01a6.254 6.254 0 01-7.03-5.37A6.254 6.254 0 01-5 8.54l.615-.045c.4-.025.99-.055 1.725-.055 1.48-.01 3.56.115 5.92.535 1.185.21 2.415.485 3.72.85 1.255.35 2.565.77 3.87 1.24 2.625.945 5.285 2.13 7.82 3.39 2.535 1.26 4.95 2.58 7.16 3.79.55.3 1.09.595 1.615.885.285.155.565.31.845.46l.765.43c1.005.565 1.955 1.1 2.835 1.59l5.85 3.285a4.932 4.932 0 011.885 6.71c-1.28 2.285-4.12 3.145-6.44 2.03z"
        fill="#FAC036"
      />
      <path
        d="M32.975 22.95l5.635 2.995c4.25 2.26 6.675 4.22 5.285 6.845-1.385 2.605-4.32 1.74-8.63-.55-1.64-.87-2.615-1.39-5.635-2.995 0 0-.17-1.465.93-3.54 1.15-2.165 2.415-2.755 2.415-2.755zM10.995 46.04l4.15 4.85c3.13 3.655 4.67 6.37 2.41 8.305-2.24 1.915-4.66.04-7.83-3.67-1.205-1.41-1.925-2.25-4.15-4.85 0 0 .375-1.425 2.165-2.955 1.865-1.595 3.255-1.68 3.255-1.68z"
        fill="#D7598B"
      />
      <path
        d="M54.285 22.83c.02-.325-.065-.645-.165-.95-.26-.795-.645-1.57-1.265-2.13-.62-.56-1.5-.88-2.31-.665-1.185.31-1.82 1.57-2.315 2.69-.515 1.17-1.055 2.355-1.875 3.335-.82.98-.805 2.17-2.08 2.27 1.06.54 1.275.125 2.335.665.38.195.765.39 1.18.505 1.465.41 2.445.07 3.52-1.015 1.07-1.08 2.025-2.305 2.655-3.69.145-.32.295-.66.32-1.015z"
        fill="#2F2F2F"
      />
      <path
        d="M42.745 26.845c.4.09 1.39.12 1.73-.105 1.85-1.235 3.46-2.92 4.335-4.965a4.266 4.266 0 01-1.075 2.77 9.835 9.835 0 002.805-4.095c.545 2.055-.215 4.23-1.195 6.115a10.357 10.357 0 002.815-4.005c.09.925-.635 3.95-1.07 4.415.665-.7 2.77-2.755 2.62-3.055.25 1.06-1.72 4.025-3.71 4.74-.415.15-.85.255-1.285.24-1.125-.035-2.21-.225-3.225-.71-.74-.36-2.325-.875-2.745-1.345z"
        fill="#D7598B"
      />
      <path
        d="M64-.505C65.245.2 66.135.985 65.985 1.25l-11.3 20.035c-.15.265-1.28-.09-2.525-.79-1.245-.7-2.13-1.485-1.98-1.75L61.48-1.29c.145-.27 1.275.08 2.52.785z"
        fill="#F48120"
      />
      <path
        d="M35.69 25.585s.135.035.365.115c.115.035.25.09.41.15.155.055.335.14.51.205.375.155.82.37 1.265.62.45.255.915.545 1.365.865.455.31.885.65 1.285.975.395.33.75.655 1.045.945.59.58.93 1.02.93 1.02l-.33.38s-.475-.275-1.15-.715c-.67-.44-1.565-1.025-2.455-1.61-.45-.29-.89-.58-1.305-.855-.415-.28-.79-.545-1.125-.785-.18-.135-.32-.235-.45-.345-.13-.105-.245-.195-.33-.275a5.643 5.643 0 01-.28-.255l.25-.435zM12.745 50.715l.25.17c.075.055.17.125.275.2l.345.265c.515.4 1.165.995 1.745 1.65.59.65 1.125 1.345 1.485 1.89.36.55.57.935.57.935l-.405.295-.725-.79c-.43-.475-1-1.105-1.58-1.72-.585-.615-1.17-1.235-1.61-1.695-.12-.13-.21-.23-.3-.335l-.22-.26c-.12-.15-.185-.235-.185-.235l.355-.37z"
        fill="#fff"
      />
      <path
        d="M20.22 51.515l-.34-.44c-.23-.28-.565-.695-.99-1.22l-1.54-1.775a79.758 79.758 0 00-2.1-2.25c-.785-.815-1.655-1.68-2.58-2.595-.925-.915-1.91-1.87-2.945-2.85-2.06-1.96-4.3-4.025-6.57-6.07-2.27-2.045-4.59-4.055-6.77-5.95-1.095-.945-2.15-1.86-3.155-2.73-.5-.425-.98-.84-1.45-1.245-.445-.375-.84-.695-1.19-.955-.71-.53-1.205-.81-1.625-.99a5.789 5.789 0 00-1.145-.36 4.965 4.965 0 00-.755-.105c-.175-.015-.265-.015-.265-.015a6.27 6.27 0 01-6.25-6.285 6.27 6.27 0 016.93-6.215l.585.065s.295.03.84.125c.545.095 1.335.255 2.31.56.98.305 2.13.75 3.385 1.445 1.255.685 2.52 1.62 3.6 2.57.54.475 1.055.96 1.53 1.425.455.445.92.905 1.405 1.375.955.935 1.96 1.915 3 2.935 1.04 1.025 2.115 2.085 3.21 3.16 1.09 1.085 2.2 2.19 3.305 3.295a355.597 355.597 0 016.45 6.61c1.02 1.07 1.995 2.12 2.91 3.125.915 1.01 1.775 1.965 2.575 2.845 1.605 1.775 2.945 3.255 3.88 4.295.46.495.82.885 1.07 1.155l.385.4a5.101 5.101 0 01-.14 7.21 5.097 5.097 0 01-7.56-.545z"
        fill="#FAC036"
      />
      <path
        d="M24.08 41.29l4.15 4.85c3.13 3.655 4.67 6.37 2.41 8.305-2.24 1.915-4.66.04-7.83-3.67-1.205-1.41-1.925-2.25-4.15-4.85 0 0 .375-1.425 2.165-2.955 1.865-1.595 3.255-1.68 3.255-1.68z"
        fill="#D7598B"
      />
      <path
        d="M25.825 45.965l.25.17c.075.055.17.125.275.2l.345.265c.515.4 1.165.995 1.745 1.65.59.65 1.125 1.345 1.485 1.89.36.55.57.935.57.935l-.405.295-.725-.79c-.43-.475-1-1.105-1.58-1.72-.585-.615-1.17-1.235-1.61-1.695-.12-.13-.21-.23-.3-.335l-.22-.26c-.12-.15-.185-.235-.185-.235l.355-.37z"
        fill="#fff"
      />
    </g>
    <defs>
      <clipPath id="prefix__clip0">
        <path fill="#fff" d="M0 0h64v64H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default StyledComponentIcon;
