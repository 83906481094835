import React, { SVGProps } from 'react';

const GatsbyIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" viewBox="0 0 64 64" {...props}>
    <path
      d="M32.003.019C14.203.019.019 14.203.019 32 .019 49.797 14.203 63.987 32 63.987c17.797 0 31.984-14.184 31.984-31.984 0-17.8-14.181-31.984-31.981-31.984zM6.97 32.28l24.754 24.755c-13.629 0-24.754-11.126-24.754-24.755zm30.594 24.197L7.528 26.44C10.027 15.315 20.043 6.97 32 6.97a25.27 25.27 0 0120.304 10.294l-3.613 3.056a20.419 20.419 0 00-16.966-8.899 20.147 20.147 0 00-19.192 13.627l26.144 26.147c6.4-2.227 11.403-7.787 12.795-14.464H40.624v-4.728h16.41c0 11.96-8.343 21.973-19.469 24.474z"
      fill="#542C85"
    />
  </svg>
);

export default GatsbyIcon;
